import React from "react"
import { Helmet } from "react-helmet"

const LegalHelmet = () => {
    return (
        <Helmet>
            <title>Horizen - Legal Documentation</title>
            <meta property="og:title" content="Horizen - Legal Documentation" />
            <meta
                name="twitter:title"
                content="Horizen - Legal Documentation"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="View Horizen legal documentation including Terms of Use and our Privacy Policy."
            />
            <meta
                property="og:description"
                content="View Horizen legal documentation including Terms of Use and our Privacy Policy."
            />
            <meta
                name="twitter:description"
                content="View Horizen legal documentation including Terms of Use and our Privacy Policy."
            />
            <meta
                name="keywords"
                content="horizen legal documentation, zencash legal, zen legal"
            />
            <link rel="canonical" href="https://www.horizen.io/legal/" />
            <meta property="og:url" content="https://www.horizen.io/legal/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default LegalHelmet
