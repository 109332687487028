import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Button from "../../components/Button"
import ContentContainer from "../../templates/ContentContainer"

type LinkItemProps = {
    label: string
    href: string
}

const LinkItem = ({ label, href }: LinkItemProps) => {
    return (
        <div className="flex flex-col justify-center items-center">
            <span className="block mb-5 text-[41px] font-bold">{label}</span>
            <Button label="Read more" href={href} />
        </div>
    )
}

const Links = () => {
    const { formatMessage } = useIntl()
    return (
        <section className="pb-72 -mt-14">
            <ContentContainer>
                <div className="flex flex-col md:flex-row justify-around space-y-16 md:space-y-0">
                    <LinkItem
                        label={formatMessage({ id: "legal.links.terms.label" })}
                        href={formatMessage({ id: "legal.links.terms.href" })}
                    />
                    <LinkItem
                        label={formatMessage({
                            id: "legal.links.privacy.label",
                        })}
                        href={formatMessage({ id: "legal.links.privacy.href" })}
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default Links
