import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import LegalHelmet from "../components/SEO/LegalHelmet"
import LegalHero from "../sections/legal/LegalHero"
import Links from "../sections/legal/Links"
import Layout from "../templates/Layout"

const Legal = () => {
    return (
        <Layout>
            <LegalHelmet />
            <div className="bg-horizen-hp-bg text-white">
                <LegalHero />
                <Links />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default Legal
